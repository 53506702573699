import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql } from 'gatsby';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { Grid, Row, Col } from 'react-styled-flexboxgrid';
import moment from 'moment';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { H2White, H4White, PSmallWhite } from '../components/typography';

const StyledGrid = styled(Grid)`
  max-width: 89rem;
  margin-bottom: 50px;
  width: 100%;

  @media (min-width: 64em) {
    margin-bottom: 106px;
  }
`;

const StyledH2White = styled(H2White)`
  margin-bottom: 35px;

  @media (min-width: 64em) {
    margin-top: 69px;
    margin-bottom: 69px;
  }
`;

const StyledPSmallWhite = styled(PSmallWhite)`
  margin-top: 0.25rem;
  margin-bottom: 1rem;
`;

const Container = styled.div`
  position: relative;
  margin-bottom: 2.25rem;

  &:hover .overlay {
    opacity: 0.9;
  }

  &:hover .title {
    color: var(--blue);
    transition: color .5s ease;
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  background-color: #008CBA;
`;

const OverlayWrapper = styled.div`
  position: relative;
`;

const Button = styled.button`
  color: var(--white);
  width: 182px;
  height: 43px;
  border: 1px solid var(--white);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  cursor: pointer;
  font-size: 1rem;
  letter-spacing: 0.32px;
  line-height: 1.125rem;
`;

const NewsIndex = ({ data }) => (
  <Layout>
    <SEO title="News" />
    <StyledGrid fluid>
      <Row>
        <Col lg={12} md={12} xs={12}>
          <StyledH2White>news</StyledH2White>
        </Col>
      </Row>
      <Row>
        {data.allDatoCmsNews.edges.map(({ node: news }) => (
          <Col lg={4} md={4} xs={12} key={news.id}>
            <Link to={`/news/${news.slug}`}>
              <Container>
                <OverlayWrapper>
                  <Img fluid={{ ...news.cover.fluid, aspectRatio: 1 }} className="news-image" />
                  <Overlay className="overlay">
                    <Button type="button">Read more</Button>
                  </Overlay>
                </OverlayWrapper>
                <H4White className="title">{news.title}</H4White>
                <StyledPSmallWhite>{moment(news.meta.publishedAt).format('MMM YYYY')}</StyledPSmallWhite>
              </Container>
            </Link>
          </Col>
        ))}
      </Row>
    </StyledGrid>
  </Layout>
);

export const query = graphql`
  query NewsIndexQuery {
    allDatoCmsNews(
      sort: {
        fields: [meta___publishedAt],
        order: DESC
      },
      filter: {meta: {isValid: {eq: true}, status: {eq: "published"}}}
    ) {
      edges {
        node {
          id
          title
          slug
          meta {
            publishedAt
            isValid
            status
          }
          cover {
            fluid(maxHeight: 375) {
              ...GatsbyDatoCmsSizes
            }
          }
        }
      }
    }
  }
`;

NewsIndex.propTypes = {
  data: PropTypes.shape().isRequired,
};

export default NewsIndex;
